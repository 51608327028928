import React, { FC } from 'react';
import styles from './date-field.module.scss';
import { SuggestionTypes, useGlobalSearchContext } from '../../../../store/global-search.context';
import { GlobalSearchInput } from '@digando/common-ui';
import { useTranslation } from 'next-i18next';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { Icon } from '@digando/react-component-library';
import { useSetRecoilState } from 'recoil';
import {
  isGlobalSearchHeaderActiveState
} from './../global-search.state';

type FromDateFieldProps = {
  identifier: string;
  compact?: boolean;
}

export const FromDateField: FC<FromDateFieldProps> = ({ identifier,     compact = false, }) => {
  const {
    openSuggestion,
    startDate,
    setStartDate,
  } = useGlobalSearchContext();
  const [t] = useTranslation(['search-component', 'common']);
  const setIsGlobalSearchHeaderActive = useSetRecoilState(isGlobalSearchHeaderActiveState);

  return (
    <>
      <div className={styles.desktopDateFieldWrapper}>
        <GlobalSearchInput
          id={`global-search-from-date-${identifier}`}
          name={`global-search-from-date-${identifier}`}
          value={startDate.format('DD.MM.YYYY')}
          defaultValue={startDate.format('DD.MM.YYYY')}
          label={t('search-component:label-start-date')}
          onChange={() => {
            return;
          }}
          onFocus={(event): void => {
            setIsGlobalSearchHeaderActive(true);
            openSuggestion(SuggestionTypes.DATE_START);
          }}
          compact={compact}
        />
      </div>

      <div className={classNames({
        [styles.mobileDateFieldWrapper]: true,
        [styles.fromDateField]: true,
      })}>
        <GlobalSearchInput
          id={`global-search-from-date-${identifier}`}
          name={`global-search-from-date-${identifier}`}
          value={startDate.format('YYYY-MM-DD')}
          defaultValue={startDate.format('YYYY-MM-DD')}
          label={t('search-component:label-start-date')}
          onChange={(value) => {
            if (0 < value.length) {
              setStartDate(dayjs(value));
            }
          }}
          type={'date'}
          min={dayjs().format('YYYY-MM-DD')}
          compact={compact}
        />

        <div className={styles.calendarIcon}>
          <Icon icon={'calendar'} />
        </div>
      </div>
    </>
  );
};
